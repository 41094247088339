import type { Graph, WithContext, Organization, WebSite } from 'schema-dts'

export function useDefaultJsonld(items: Graph['@graph']) {
  const runtimeConfig = useRuntimeConfig()
  const siteUrl = runtimeConfig.public.siteUrl
  // default structured data
  const website: WithContext<WebSite> = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: runtimeConfig.public.siteName,
    url: siteUrl,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `${siteUrl}/jobs?search={search_term_string}`
      },
      // @ts-ignore
      'query-input': 'required name=search_term_string'
    }
  }

  const localBusiness: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    logo: 'https://techjobasia.com/images/tja_logo.png',
    image: 'https://techjobasia.com/images/tja_logo.png',
    name: runtimeConfig.public.siteName,
    address: [
      {
        '@type': 'PostalAddress',
        streetAddress: 'Level 5, Core F, Cyberport 3, 100',
        addressLocality: 'Cyberport Road',
        addressRegion: 'Southern',
        postalCode: '',
        addressCountry: 'HK'
      },
      {
        '@type': 'PostalAddress',
        streetAddress: '19, Jalan USJ Heights 1/1B, USJ Heights Avenue',
        addressLocality: 'Subang Jaya',
        addressRegion: 'Selangor',
        postalCode: '47500',
        addressCountry: 'MY'
      },
      {
        '@type': 'PostalAddress',
        streetAddress: 'OCBC Center East',
        addressLocality: 'Chulia Street',
        addressRegion: 'Central',
        postalCode: '049514',
        addressCountry: 'SG'
      }
    ],
    url: siteUrl,
    telephone: ['+6012 671 0102', '+852-3166 3912'],
    description: `Discover premium technology and digital job opportunities in Asia with ${runtimeConfig.public.siteName}. As the leading job board specializing in the tech industry, we connect talented professionals with top-tier companies offering exceptional working environments, competitive packages, and exciting career prospects. Explore our extensive listings to find your next career move in software development, data science, digital marketing, UX/UI design, cybersecurity, and more.`
  }

  let graphItems: Graph['@graph'] = [website, localBusiness]
  graphItems = graphItems.concat(items)
  return useJsonld(
    () =>
      ({
        '@context': 'https://schema.org',
        '@graph': graphItems
      }) as Graph
  )
}
